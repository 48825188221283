import React, { Component } from "react";

class ImprintModal extends Component {
  render() {
    let today = new Date();
    let currentYear = today.getFullYear();

    return (
      <div>
        <p>© {currentYear}</p>

        <div>Anja Ressmann</div>
        <div>9020 Klagenfurt</div>
        <div>Austria</div>

        <p>
          Contact e-mail: <a href="mailto:hello@anja.codes">hello@anja.codes</a>
        </p>

        <h3>Photo Credit</h3>
        <p>
          The pictures, photos and graphics on this website are protected by
          copyright. The image rights are owned by the following photographers
          and companies:
        </p>
        <ul>
          <li>
            Dipl.-Ing. Martin Steinthaler -{" "}
            <a href="https://www.tinefoto.com/">https://tinefoto.com</a>
          </li>
          <li>Christina Schintlmeister</li>
        </ul>

        <h3>Other used resources</h3>
        <ul>
          <li>
           Fonts by Adobe Fonts -{" "}
            <a href="https://fonts.adobe.com">https://fonts.adobe.com</a>
          </li>
          <li>Icons by FontAwesome -{" "}
            <a href="https://fontawesome.com">https://fontawesome.com</a></li>
        </ul>

        <h3>Permitted use of the website</h3>
        <p>
          This website and its contents are subject to copyright law. Unless
          expressly permitted by law, every form of utilizing, reproducing or
          processing works subject to copyright protection on this website
          requires the prior consent of the respective owner of the rights Anja
          Ressmann. The materials from this website is copyrighted and any
          unauthorized use may violate copyright laws.
        </p>

        <h3>Links</h3>
        <p>
          We are not responsible for the content of internet sites to which this
          website provides links. We have no influence on the content and the
          opinions expressed on linked sites. We provide these third party links
          only as a convenience to visitors to our website and the inclusion of
          these links does not imply endorsement by anja.codes of the content of
          the linked sites. If you decide to leave the anja.codes website, you
          do so at your own risk. anja.codes disclaims all warranties, express
          or implied, as to the accuracy, legality, reliability or validity of
          any content of the linked website.
        </p>

        <h3>Legal notice</h3>
        <p>
          Any liability for errors and mistakes on this website is excluded to
          the extent permitted by law. Should you discover errors or mistakes or
          if you believe that the names or other contents used violate laws or
          your own property rights, please inform us informally using the
          contact form so that appropriate corrections can be made as quickly as
          possible.
        </p>
      </div>
    );
  }
}

export default ImprintModal;
