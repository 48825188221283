import React, { Component } from "react";
import { Modal, fixedBody } from "./helpers/Modal";
import ImprintModal from "./ImprintModal";
import DataPrivacyModal from "./DataPrivacyModal";

class Footer extends Component {
  state = {
    showModalImprint: false,
    showModalDataPrivacy: false,
  };

  toggleModal = (show, modal) => {
    this.setState({ [modal]: show });
    let bodyToggle = show ? "hidden" : "visible";
    fixedBody(bodyToggle);
  };

  render() {
    return (
      <>
        <div className="footer">
          <div>
            Made with <i className="fa fa-heart" />
          </div>
          <div>
            <button onClick={() => this.toggleModal(true, "showModalImprint")}>
              Copyright
            </button>
            |
            <button
              onClick={() => this.toggleModal(true, "showModalDataPrivacy")}
            >
              Privacy Policy
            </button>
          </div>
        </div>

        <Modal
          isShown={this.state.showModalImprint}
          hide={() => this.toggleModal(false, "showModalImprint")}
          modalContent={<ImprintModal />}
          headerText={"Copyright"}
        />

        <Modal
          isShown={this.state.showModalDataPrivacy}
          hide={() => this.toggleModal(false, "showModalDataPrivacy")}
          modalContent={<DataPrivacyModal />}
          headerText={"Privacy Policy"}
        />
      </>
    );
  }
}

export default Footer;
