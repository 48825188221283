import React, { Component } from "react";
import { FadeIn } from "./helpers/FadeIn";

class Services extends Component {
  render() {
    return (
      <>
        <h1 className="section-header" id="services" ref={this.props.refName} >What I can do for You</h1>
        <div className="services">
          <div className="service-cards">
            <FadeIn>
            <div className="service-card">
              <h2>UI/UX Design</h2>
              <div className="service-items">
                <ul>
                  <li>Interface mock-ups for websites and apps</li>
                  <li>Optimized user experiences</li>
                  <li>Color and font pairings (individual branding)</li>
                  <li>Responsive design</li>
                  <li>Accessible design</li>
                  <li>
                    Intuitive interactions and workflows
                  </li>
                </ul>
              </div>
              <div className="service-tools">
                <h3>Tools:</h3>
                <div className="tag-wrapper">
                  <div className="tag">Adobe Illustrator</div>
                  <div className="tag">Adobe XD</div>
                  <div className="tag">Adobe Premiere</div>
                  <div className="tag">Procreate</div>
                  <div className="tag">Blender</div>
                </div>
              </div>
            </div>
            </FadeIn>

            <FadeIn fast>
            <div className="service-card">
              <h2>Web Applications</h2>
              <div className="service-items">
                <ul>
                  <li>Flexible web development for individual purposes</li>
                  <li>
                    Cross-platform applications for all browsers on all devices
                  </li>
                  <li>Secure data management</li>
                </ul>
              </div>
              <div className="service-tools">
                <h3>Tools:</h3>
                <div className="tag-wrapper">
                  <div className="tag">JavaScript</div>
                  <div className="tag">React</div>
                  <div className="tag">VueJS</div>
                  <div className="tag">HTML5</div>
                  <div className="tag">CSS</div>
                  <div className="tag">SASS</div>
                  <div className="tag">Tailwind</div>
                  <div className="tag">C#</div>
                  <div className="tag">.Net Framework</div>
                  <div className="tag">Python</div>
                  <div className="tag">Java</div>
                  <div className="tag">SQL</div>
                </div>
              </div>
            </div>
            </FadeIn>

            <FadeIn slow>
            <div className="service-card">
              <h2>Other Services</h2>
              <div className="service-items">
                <ul>
                  <li>
                    Hosting
                  </li>
                  <li>Domain registration &amp; management</li>
                  <li>SEO / Open Graph</li>
                  <li>Security via HTTPS</li>
                </ul>
              </div>
            </div>
            </FadeIn>

          </div>
        </div>
      </>
    );
  }
}

export default Services;
