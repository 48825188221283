import React, { Component } from "react";
import { FadeIn } from "./helpers/FadeIn";

class PortfolioItem extends Component {
  render() {
    let pI = this.props.portfolioItem;

    return (
      <div className="portfolio-item">
        <div className="portfolio-card">
          <div className="portfolio-text">
            <h2>{pI.title}</h2>
            <p>{pI.description}</p>
          </div>
          <FadeIn>
            <div className="img-wrapper">
              <img src={pI.image} alt={pI.title} />
            </div>
          </FadeIn>
          <div className="portfolio-text">
            <a href={pI.url} title={"Link to " + pI.title}>
              Check it out here
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default PortfolioItem;
