import React, { Component } from "react";
import background from "../images/background.jpeg";
import { FadeIn } from "./helpers/FadeIn";

import { inject, observer } from "mobx-react";

class Contact extends Component {
  state = {
    validEmail: true,
    validText: true,
    alerts: [],
  };

  alertCounter = 0;

  eMailRef = React.createRef();
  textRef = React.createRef();

  onSend = () => {
    let validEmail = this.validateEmail();
    let validText = this.validateText();

    this.setState({ validText: validText, validEmail: validEmail });

    if (validText && validEmail) {
      var message = {
        email: this.eMailRef.current.value,
        message: this.textRef.current.value,
      };

      this.props.telegramStore
        .sendMessage(message)
        .then((resp) => {
          this.eMailRef.current.value = "";
          this.textRef.current.value = "";

          this.setAlertArray(
            true,
            "Message successfully sent! I will get back to you as soon as possible!"
          );
        })
        .catch((e) => {
          this.setAlertArray(
            false,
            "An error occurred while sending the message - I will deploy my pet velociraptors to work on a solution!"
          );
        });
    }
  };

  validateEmail() {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(this.eMailRef.current.value).toLowerCase());
  }

  validateText() {
    let str = this.textRef.current.value;
    return !(!str || /^\s*$/.test(str));
  }

  validateMessage = () => {
    if (this.textRef.current.value === "") {
      this.setState({ validText: true });
    } else this.setState({ validText: this.validateText() });
  };

  setAlertArray = (successful, text) => {
    var counter = this.alertCounter;
    this.alertCounter++;
    this.setState(
      {
        alerts: [
          ...this.state.alerts,
          {
            id: counter,
            successful: successful,
            alertMessage: text,
          },
        ],
      },
      () => {
        setTimeout(() => {
          var array = [...this.state.alerts];
          var index = array.findIndex((item) => item.id === counter);
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ alerts: array });
          }
        }, 5000);
      }
    );
  };

  render() {
    let alerts = this.state.alerts.map((item) => (
      <div className={"popover"} key={item.id}>
        {item.successful ? (
          <i className="fa fa-check" />
        ) : (
          <i className="fa fa-times" />
        )}{" "}
        {item.alertMessage}
      </div>
    ));

    return (
      <div className="contact" id="contact" ref={this.props.refName}>
        {alerts}
        <div className="background-image">
          <img src={background} alt="background" />{" "}
        </div>
        <div className="background-overlay" />
        <div className="background-container">
          <FadeIn slow>
            <div className="contact-card">
              <h1>Contact</h1>

              <label htmlFor="email">E-Mail</label>
              <input
                className={this.state.validEmail ? "" : "invalid"}
                id="email"
                type="email"
                ref={this.eMailRef}
                placeholder="Your E-Mail"
              />
              <div
                className={this.state.validEmail ? "hidden" : "invalid-text"}
              >
                <i className="fa fa-exclamation-triangle" /> Please enter a valid
                e-mail address!
              </div>

              <label htmlFor="text">Message</label>
              <textarea
                onChange={this.validateMessage}
                className={this.state.validText ? "" : "invalid"}
                id="text"
                type="text"
                ref={this.textRef}
                placeholder="Your Message for me"
                rows="6"
              />
              <div className={this.state.validText ? "hidden" : "invalid-text"}>
                <i className="fa fa-exclamation-triangle" /> Please enter a
                message you would like to send!
              </div>

              <div className="direct">
                <span>Or e-mail me directly:</span>
                <div className="e-mail">
                  <i className="fa fa-envelope" />
                  <a href="mailto:hello@anja.codes">hello@anja.codes</a>
                </div>
              </div>

              <div className="button-wrapper">
                <button onClick={this.onSend}>Send!</button>
              </div>
            </div>
          </FadeIn>
        </div>

        <div className="social-btns-container">
          <a
            href="https://instagram.com/_anress/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fab fa-instagram" />
          </a>
          <a
            href="https://twitter.com/anjress"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fab fa-twitter" />
          </a>
          <a
            href="https://dribbble.com/anja_codes/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fab fa-dribbble" />
          </a>
        </div>
      </div>
    );
  }
}

export default inject("telegramStore")(observer(Contact));
