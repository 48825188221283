import React, { Component } from "react";
import background from "../images/background.jpeg";
import { FadeIn } from "./helpers/FadeIn";

class TopBanner extends Component {
  render() {
    return (
      <div className="top-banner" id="home" ref={this.props.refName}>
        <div className="background-image">
          <img src={background} alt="background" />{" "}
        </div>
        <div className="background-overlay" />
        <div className="background-container">
          <FadeIn>
            <div className="banner-card">
              <h1>UX/UI Design &amp; Web Development</h1>
              <p>
                Stunning and responsive web applications that elevate your
                online presence.
              </p>
              <div className="button-wrapper">
                <button
                  onClick={(e) => this.props.scrollTo(e, "sectionContact")}
                >
                  Get in touch
                </button>
                <button
                  onClick={(e) => this.props.scrollTo(e, "sectionPortfolio")}
                >
                  See my work
                </button>
              </div>
            </div>
          </FadeIn>
        </div>
      </div>
    );
  }
}

export default TopBanner;
