import React, { Component } from "react";
import { Provider } from "mobx-react";

import TelegramStore from "../stores/TelegramStore";

import "../styles/App.scss";
import Navigation from "./Navigation";
import TopBanner from "./TopBanner";
import AboutMe from "./AboutMe";
import Services from "./Services";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
import Footer from "./Footer";

import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

class App extends Component {
  state = {
    hideMobileMenu: true,
  };

  sectionTopBanner = React.createRef();
  sectionAboutMe = React.createRef();
  sectionServices = React.createRef();
  sectionPortfolio = React.createRef();
  sectionContact = React.createRef();

  scrollTo = (e, refName) => {
    e.preventDefault();
    var scroll = 0;
    if (refName !== "sectionTopBanner")
      scroll = this[refName].current.offsetTop - 80;

    window.scrollTo({ top: scroll, behavior: "smooth" });

    setTimeout(() => this.toggleMobileMenu(true), 100);
  };

  toggleMobileMenu = (close) => {
    this.setState({ hideMobileMenu: close });
  };

  render() {
    return (
      <Provider telegramStore={TelegramStore}>
        <div className="main-content">
          <Navigation
            scrollTo={this.scrollTo}
            toggleMobileMenu={this.toggleMobileMenu}
            hideMobileMenu={this.state.hideMobileMenu}
          />
          <div className="main">
            <TopBanner
              refName={this.sectionTopBanner}
              scrollTo={this.scrollTo}
            />
            <AboutMe refName={this.sectionAboutMe} />
            <Services refName={this.sectionServices} />
            <Portfolio refName={this.sectionPortfolio} />
            <Contact refName={this.sectionContact} />
            <Footer />
          </div>
        </div>
      </Provider>
    );
  }
}

export default App;
