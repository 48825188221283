import React, { Component } from "react";
import PortfolioItem from "./PortfolioItem";
import emp from "../images/portfolio/emp2.png";
import vcs from "../images/portfolio/vcst_1.png";
import praxis from "../images/portfolio/praxis-klagenfurt.png";
import beha from "../images/portfolio/behackl_1.png";
import hash from "../images/portfolio/hashtagchess_1.png";
import chinchillas from "../images/portfolio/chaos-chinchillas.png";
import messenger from "../images/portfolio/messenger-app.png";

class Portfolio extends Component {
  render() {
    let empathyTraining = {
      title: "EmpathyTRAINing",
      description:
        "Your rights are in Danger! Website for MEP Nico Semsrott for protecting passenger rights in the EU.",
      image: emp,
      url: "https://dev.anja.codes/empathytraining",
    };

    let hashtagchess = {
      title: "HashtagChess Bingo",
      description:
        "Are you the first one to get 4 in a row? Bingo Game for Twitch stream HashtagChess.",
      image: hash,
      url: "https://hashtagchess.club/bingo",
    };

    let praxisKlagenfurt = {
      title: "Praxis Klagenfurt",
      description: "Homepage for dermatologist Dr. Sylvia Hackl.",
      image: praxis,
      url: "https://praxis-klagenfurt.at",
    };

    let behackl = {
      title: "benjamin-hackl.at",
      description: "Website design for mathematical researcher Benjamin Hackl.",
      image: beha,
      url: "https://benjamin-hackl.at",
    };

    let vcst = {
      title: "Video Content Search Tool",
      description:
        "Search keyframes of videos by visual content search. In the background the application works with video shot detection and deep learning.",
      image: vcs,
      url: "https://vcst.anja.codes",
    };

    let chinchillaHomepage = {
      title: "Chaos Chinchillas",
      description:
        "Landing page for social media account Chaos Chinchillas with information surrounding chinchillas.",
      image: chinchillas,
      url: "https://chaos.chinchilla.party",
    }


    let messengerApp = {
      title: "Microblog Concept",
      description:
        "Concept application for posting and exploring short messages with a tag. The log in prompt accepts any user name and password combination.",
      image: messenger,
      url: "https://dev.anja.codes/rb-challenge",
    }

    return (
      <div className="portfolio" id="portfolio" ref={this.props.refName}>
        <h1 className="section-header">Portfolio</h1>
        <PortfolioItem portfolioItem={empathyTraining} />
        <PortfolioItem portfolioItem={messengerApp} /> 
        <PortfolioItem portfolioItem={chinchillaHomepage} />
        <PortfolioItem portfolioItem={vcst} />
        <PortfolioItem portfolioItem={behackl} />
      </div>
    );
  }
}

export default Portfolio;
