import React  from 'react';
import ReactDOM from 'react-dom';

export function fixedBody (visbility) {
  document.getElementsByTagName('body')[0].style.overflow = visbility;
}

export const Modal = ({
  isShown,
  hide,
  modalContent,
  headerText,
}) => {
  const modal = (
    <React.Fragment>
      <div className="modal-backdrop" />
      <div className="modal-wrapper">
        <div className="modal">
          <div className="modal-header">
            <h2 className="header-text">{headerText}</h2>
            <button className="close-btn" aria-label="close" onClick={hide}>X</button>
          </div>
          <div className="content">{modalContent}</div>
        </div>
      </div>
    </React.Fragment>
  );
  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};