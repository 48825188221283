import React from 'react';
 
export function FadeIn(props) {
  const [isVisible, setVisible] = React.useState(true);
  const domRef = React.useRef();
  // const shown = React.useRef().current;
 
  React.useEffect(() => {
    let shown = false;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!shown) {
         setVisible(entry.isIntersecting);
          if(entry.isIntersecting) {
            shown = true;
          }
        }
      });
    });
    var refDom = domRef.current;
    observer.observe(refDom);
    return () => observer.unobserve(refDom);
  }, []);
 
  return (
    <div
      className={`fade-in ${isVisible ? 'is-visible' : ''} ${props.fast ? 'fast' : ''} ${props.slow ? 'slow' : ''}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}
