import React, { Component } from "react";
import Scrollspy from "react-scrollspy";
import logo from "../images/logo.svg";

class Navigation extends Component {
  state = {
    prevScrollPos: 0,
    borderedHeader: false,
    hideHeader: false,
    hideLogo: false
  };

  menu = (
    <Scrollspy
      items={["home", "services", "portfolio", "contact"]}
      currentClassName="current-section"
      className="menu-scrollspy"
    >
      <li>
        <a
          href="#home"
          onClick={(e) => this.props.scrollTo(e, "sectionTopBanner")}
        >
          Home
        </a>
      </li>
      <li>
        <a
          href="#services"
          onClick={(e) => this.props.scrollTo(e, "sectionServices")}
        >
          Services
        </a>
      </li>
      <li>
        <a
          href="#portfolio"
          onClick={(e) => this.props.scrollTo(e, "sectionPortfolio")}
        >
          Portfolio
        </a>
      </li>
      <li>
        <a
          href="#contact"
          onClick={(e) => this.props.scrollTo(e, "sectionContact")}
        >
          Contact
        </a>
      </li>
    </Scrollspy>
  );

  onScroll = () => {
    var currentScrollPos = window.pageYOffset;
    var hide = false;
    var hideLogo = false;
    var bordered = false;

    if (currentScrollPos > 10) {
      bordered = true;
    }

    if (this.state.prevScrollPos > currentScrollPos) {
      if(currentScrollPos > 90) {
        hideLogo = true;
      }
      hide = false;
    } else if (currentScrollPos > 350) {
      hide = true;
    }

    this.setState({
      prevScrollPos: currentScrollPos,
      hideHeader: hide,
      borderedHeader: bordered,
      hideLogo: hideLogo
    });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, 10);
  }

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.onScroll);
  };

  render() {
    return (
      <header
        className={
          "navigation" +
          (this.state.borderedHeader ? " border-bottom" : "") +
          (this.state.hideHeader & this.props.hideMobileMenu
            ? " hide-to-top"
            : "") +
            (this.state.hideLogo & this.props.hideMobileMenu
              ? " hide-logo-to-top"
              : "") +
          (this.props.hideMobileMenu ? "" : " fixed")
        }
      >

      <img src={logo} className="logo logo-left" alt="palm tree logo"/>
        <div className="header">
          <div className="placeholder" />
          <div
            className="header-text"
            onClick={(e) => this.props.scrollTo(e, "sectionTopBanner")}
          >
            <div>anja.codes</div>
            <div className="sub-header">Web Design &amp; Development</div>
          </div>
          <button
            className="toggle-menu-btn"
            onClick={(e) => this.props.toggleMobileMenu(false)}
          >
            <i className="fa fa-bars" />
          </button>
          <div className="navigation-items" role="navigation">
            {this.menu}
          </div>
        </div>

        <div
          className={
            "backdrop" + (this.props.hideMobileMenu ? " hide" : " show")
          }
        />

        <div
          className={
            "mobile-menu" + (this.props.hideMobileMenu ? " hide" : " show")
          }
        >
          <button
            className="toggle-menu-btn"
            onClick={(e) => this.props.toggleMobileMenu(true)}
          >
            <i className="fa fa-times" />
          </button>
          {this.menu}
        </div>


      <img src={logo} className="logo logo-right" alt="palm tree logo"/>
      </header>
    );
  }
}

export default Navigation;
