import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

class TelegramStore {
sendMessage(message) {
      return axios.post("/api/message", message, {
      withCredentials: true
    });
  }
}

export default new TelegramStore();
