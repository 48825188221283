import React, { Component } from "react";
import profile from "../images/profile.jpg";
import { FadeIn } from "./helpers/FadeIn";

class AboutMe extends Component {
  getAge = () => {
    let today = new Date();
    let birthday = new Date(1997, 7, 21);
    return parseInt((today - birthday) / 31536000000);
  };

  getWorkExperienceYears = () => {
    let today = new Date();
    let workStart = new Date(2017, 7, 1);
    return parseInt((today - workStart) / 31536000000);
  }

  render() {

    return (
      <div className="about-me">
        <div className="portrait-wrapper">
          <img className="portrait" src={profile} alt="Portrait Anja" />
        </div>
        <FadeIn slow>
          <div className="about-me-card">
            <h1>Hi, I am Anja!</h1>
            <p>
              I am a {this.getAge()} year old UI/UX designer and software developer. I come
              with {this.getWorkExperienceYears()} years of professional experience in web development and design. Additionally, I have a
              master's degree in computer science.
            </p>
          </div>
        </FadeIn>
      </div>
    );
  }
}

export default AboutMe;
