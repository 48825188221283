import React, { Component } from "react";

class DataPrivacyModal extends Component {
  render() {
    return (
      <div>
        <p>
          We created this Privacy Policy (version 16.11.2020-111475593), to
          declare which information we collect, how we use data and which
          options the users of our website have, according to the guidelines of
          the{" "}
          <a
            href="https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=celex%3A32016R0679&amp%3Btid= 111475593"
            target="_blank"
            rel="noopener noreferrer"
          >
            General Data Protection Regulation (EU) 2016/679
          </a>
        </p>
        <p>
          Unfortunately, these subjects sound rather technical due to their
          nature, but we have put much effort into describing the most important
          things as simply and clearly as possible.
        </p>
        <h3>Automatic Data Retention</h3>
        <p>
          Every time you visit a website nowadays, certain information is
          automatically created and saved, just as it happens on this website.
        </p>
        <p>
          Whenever you visit our website such as you are doing right now, our
          webserver (computer on which this website is saved/stored)
          automatically saves data such as
        </p>
        <ul>
          <li>the address (URL) of the accessed website</li>
          <li>browser and browser version</li>
          <li>the used operating system</li>
          <li>
            the address (URL) of the previously visited site (referrer URL)
          </li>
          <li>
            the host name and the IP-address of the device the website is
            accessed from
          </li>
          <li>date and time</li>
        </ul>
        <p>in files (webserver-logfiles).</p>
        <p>
          Generally, webserver-logfiles stay saved for two weeks and then get
          deleted automatically. We do not pass this information to others, but
          we cannot exclude the possibility that this data will be looked at in
          case of illegal conduct.
        </p>
        <h3>Storage of Personal Data</h3>
        <p>
          Any personal data you electronically submit to us on this website,
          such as your name, email address, home address or other personal
          information you provide via the transmission of a form or via any
          comments to the blog, are solely used for the specified purpose and
          get stored securely along with the respective submission times and
          IP-address. These data do not get passed on to third parties.
        </p>
        <p>
          Therefore, we use personal data for the communication with only those
          users, who have explicitly requested being contacted, as well as for
          the execution of the services and products offered on this website. We
          do not pass your personal data to others without your approval, but we
          cannot exclude the possibility this data will be looked at in case of
          illegal conduct.
        </p>
        <p>
          If you send us personal data via email – and thus not via this website
          – we cannot guarantee any safe transmission or protection of your
          data. We recommend you, to never send confidential data via email.
        </p>
        <h3>
          Rights in accordance with the General Data Protection Regulation
        </h3>
        <p>
          You are granted the following rights in accordance with the provisions
          of the{" "}
          <a
            href="https://gdpr-info.eu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            GDPR
          </a>{" "}
          (General Data Protection Regulation) and the Austrian{" "}
          <a
            href="https://www.ris.bka.gv.at/Dokumente/Erv/ERV_1999_1_165/ERV_1999_1_165.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Data Protection Act (DSG)
          </a>
          :
        </p>
        <ul>
          <li>right to rectification (article 16 GDPR)</li>
          <li>right to erasure (“right to be forgotten“) (article 17 GDPR)</li>
          <li>right to restrict processing (article 18 GDPR)</li>
          <li>
            righ to notification – notification obligation regarding
            rectification or erasure of personal data or restriction of
            processing (article 19 GDPR)
          </li>
          <li>right to data portability (article 20 GDPR)</li>
          <li>Right to object (article 21 GDPR)</li>
          <li>
            right not to be subject to a decision based solely on automated
            processing – including profiling – (article 22 GDPR)
          </li>
        </ul>
        <p>
          If you think that the processing of your data violates the data
          protection law, or that your data protection rights have been
          infringed in any other way, you can lodge a complaint with your
          respective regulatory authority. For Austria this is the data
          protection authority, whose website you can access at{" "}
          <a href="https://www.data-protection-authority.gv.at/?tid=111475593">
            https://www.data-protection-authority.gv.at/
          </a>
          .
        </p>
        <h3>Evaluation of Visitor Behaviour</h3>
        <p>
          In the following Privacy Policy, we will inform you on if and how we
          evaluate the data of your visit to this website. The evaluation is
          generally made anonymously, and we cannot link to you personally based
          on your behaviour on this website.
        </p>
        <p>
          You can find out more about how to disagree with the evaluation of
          visitor data, in the Privacy Policy below.
        </p>
        <h3>TLS encryption with https</h3>
        <p>
          We use https to transfer information on the internet in a tap-proof
          manner (data protection through technology design{" "}
          <a
            href="https://eur-lex.europa.eu/legal-content/en/TXT/HTML/?uri=CELEX:32016R0679&from=EN&tid=111475593"
            target="_blank"
            rel="noopener noreferrer"
          >
            Article 25 Section 1 GDPR
          </a>
          ). With the use of TLS (Transport Layer Security), which is an
          encryption protocol for safe data transfer on the internet, we can
          ensure the protection of confidential information. You can recognise
          the use of this safeguarding tool by the little lock-symbol, which is
          situated in your browser’s top left corner, as well as by the use of
          the letters https (instead of http) as a part of our web address.
        </p>
        <p>
          Source: Created with the Datenschutz Generator by{" "}
          <a
            href="https://www.adsimple.at/seo-suchmaschinenoptimierung/"
            title="AdSimple® Search Engine Optimization"
            rel="noopener noreferrer"
            target="_blank"
          >
            AdSimple® SEO
          </a>{" "}
          in cooperation with{" "}
          <a
            href="https://www.bauguide.at/"
            target="_blank"
            rel="noopener noreferrer"
          >
            bauguide.at
          </a>
        </p>
      </div>
    );
  }
}

export default DataPrivacyModal;
